import { Search } from "lucide-react";
import { Button } from "../../ui/Button";
import { Input } from "../../ui/Input";
import { Select, SelectItem } from "../../ui/Select";

interface SearchHeaderProps {
  searchParams: {
    query: string;
    salaryRange: string;
    employmentType: string;
    location: string;
  };
  onSearchChange: (updates: Partial<SearchHeaderProps["searchParams"]>) => void;
}

export default function SearchHeader({
  searchParams,
  onSearchChange,
}: SearchHeaderProps) {
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you could trigger an actual search API call
    console.log("Searching with params:", searchParams);
    // Add your search logic here, for example:
    // fetchJobs(searchParams);
    // or
    // updateSearchResults(searchParams);
  };

  const handleSelectChange =
    (field: keyof SearchHeaderProps["searchParams"]) => (value: string) => {
      onSearchChange({ [field]: value });
    };

  return (
    <div className="st-search-header">
      <form onSubmit={handleSearch} className="st-search-header__container">
        <div className="st-search-header__search-wrapper">
          <Search className="st-search-header__search-icon" />
          <Input
            type="text"
            placeholder="Product designer"
            className="st-search-header__input"
            value={searchParams.query}
            onChange={(e) => onSearchChange({ query: e.target.value })}
          />
        </div>
        <Select
          defaultValue={searchParams.salaryRange}
          label="Salary range"
          onValueChange={handleSelectChange("salaryRange")}
        >
          <SelectItem value="0">R0 - R249,999</SelectItem>
          <SelectItem value="250000">R250,000 - R499,999</SelectItem>
          <SelectItem value="500000">R500,000 - R749,999</SelectItem>
          <SelectItem value="750000">R750,000 - R999,999</SelectItem>
          <SelectItem value="1000000">R1,000,000 - R1,249,999</SelectItem>
          <SelectItem value="1250000">R1,250,000+</SelectItem>
        </Select>
        <Select
          defaultValue={searchParams.employmentType}
          label="Employment type"
          onValueChange={handleSelectChange("employmentType")}
        >
          <SelectItem value="full-time">Full-time</SelectItem>
          <SelectItem value="part-time">Part-time</SelectItem>
          <SelectItem value="contract">Contract</SelectItem>
        </Select>
        <Select
          defaultValue={searchParams.location}
          label="Location"
          onValueChange={handleSelectChange("location")}
        >
          <SelectItem value="any">Any Location</SelectItem>
          <SelectItem value="Umhlanga">Umhlanga</SelectItem>
          <SelectItem value="Mabopane">Mabopane</SelectItem>
          <SelectItem value="Potchefstroom">Potchefstroom</SelectItem>
          <SelectItem value="Westridge Centre">Westridge Centre</SelectItem>
          <SelectItem value="Durban">Durban</SelectItem>
          <SelectItem value="Pietermaritzburg">Pietermaritzburg</SelectItem>
          <SelectItem value="East Rand">East Rand</SelectItem>
          <SelectItem value="Roodepoort">Roodepoort</SelectItem>
          <SelectItem value="Johannesburg">Johannesburg</SelectItem>
          <SelectItem value="Benoni">Benoni</SelectItem>
          <SelectItem value="ZAMOKUHLE , CARSTENHOF, BUSAMED AND ARWYP LABS">
            ZAMOKUHLE, CARSTENHOF, BUSAMED AND ARWYP LABS
          </SelectItem>
        </Select>
        <Button
          type="submit"
          className="st-search-header__button"
          style={{ border: "none" }}
        >
          Find offers
        </Button>
      </form>
    </div>
  );
}
