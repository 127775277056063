import * as RadixSelect from "@radix-ui/react-select";
import { Check, ChevronDown } from "lucide-react";
import * as React from "react";

interface SelectProps {
  label: string;
  defaultValue?: string;
  children: React.ReactNode;
  onValueChange?: (value: string) => void;
}

export const Select = React.forwardRef<HTMLButtonElement, SelectProps>(
  ({ children, label, defaultValue, onValueChange, ...props }, ref) => (
    <RadixSelect.Root defaultValue={defaultValue} onValueChange={onValueChange}>
      <RadixSelect.Trigger
        className="ui-select__trigger"
        aria-label={label}
        ref={ref}
        {...props}
      >
        <RadixSelect.Value />
        <RadixSelect.Icon>
          <ChevronDown className="ui-select__icon" />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>

      <RadixSelect.Portal>
        <RadixSelect.Content className="ui-select__content">
          <RadixSelect.Viewport>{children}</RadixSelect.Viewport>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  )
);

export const SelectItem = React.forwardRef<
  HTMLDivElement,
  RadixSelect.SelectItemProps
>(({ children, ...props }, ref) => (
  <RadixSelect.Item {...props} ref={ref} className="ui-select__item">
    <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
    <RadixSelect.ItemIndicator className="ui-select__item-indicator">
      <Check className="ui-select__check" />
    </RadixSelect.ItemIndicator>
  </RadixSelect.Item>
));
