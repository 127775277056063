import { React, useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import TextTransition, { presets } from "react-text-transition";
import HeroSlider from "../Slider/HeroSlider";
import Social from "../Social/Social";

const Hero = ({ data }) => {
  const bgImg = data.bgImg;
  const bgShape = data.bgShape;
  const sliderImages = data.sliderImages;
  const title = data.title;

  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      4000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div
      id="home"
      className="st-hero-wrap st-gray-bg st-dynamic-bg overflow-hidden st-fixed-bg"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="st-hero st-style1">
        <div className="container">
          <div className="st-hero-text">
            <div className="st-height-b40 st-height-lg-b40" />
            <h1 className="st-hero-title cd-headline slide">
              Clinical lab training <br />
              for
              <span className="cd-words-wrapper">
                <TextTransition springConfig={presets.wobbly}>
                  <span className="st-transition-title">
                    {title[index % title.length]}
                  </span>
                </TextTransition>
              </span>
            </h1>
            <h1 className="st-hero-subtitle">
              Fast-track your career. Learn from the best in the industry.
              <br />
              Unlock your potential: 30+ years of expertise at your fingertips
            </h1>
            <div className="st-hero-btn">
              <ScrollLink
                to="contact"
                spy={true}
                duration={500}
                className="st-btn st-style1 st-color1 st-smooth-move"
              >
                {" "}
                Contact{" "}
              </ScrollLink>
            </div>
          </div>
        </div>
        <div className="st-hero-social-group">
          <div className="container">
            <Social />
          </div>
        </div>
      </div>
      <HeroSlider data={sliderImages} />
      <div className="st-hero-shape">
        <img src={bgShape} alt={bgShape} />
      </div>
    </div>
  );
};

export default Hero;
