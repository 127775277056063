import { useState } from "react";
import Modal from "../Modal/Modal";

const CommercialBanner = ({ data }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);

  const openModal = (book) => {
    setSelectedBook(book);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedBook(null);
  };

  return (
    <section className="st-commercial-banner">
      <div className="container">
        <div className="st-height-b50 st-height-lg-b50" />
        <div className="row">
          {data.map((book, index) => (
            <div key={index} className="col-lg-6 mb-4 col-md-12 col-sm-6">
              <div className="st-book-card" onClick={() => openModal(book)}>
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="st-book-image-wrapper">
                      <img
                        src={book.image}
                        alt={book.title}
                        className="st-book-image"
                      />
                    </div>
                    <div className="st-book-content text-center">
                      <h3 className="st-book-title">{book.title}</h3>
                      <h5 className="st-coming-soon">{`Coming Soon`}</h5>
                      <div className="st-book-price">R{book.price}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="st-height-b50 st-height-lg-b50" />
      </div>

      <Modal
        isOpen={modalIsOpen}
        hideButton={true}
        onClose={closeModal}
        book={selectedBook}
      />
    </section>
  );
};

export default CommercialBanner;
