import { Button } from "../../ui/Button";

export default function CTASidebar() {
  return (
    <aside className="st-cta-sidebar">
      <div className="st-cta-sidebar__card">
        <h3 className="st-cta-sidebar__title">
          Feeling stuck? Looking for a Career Coach?
        </h3>
        <p className="st-cta-sidebar__description">
          I can help you navigate your career path, achieve your goals, and find
          success.
        </p>
        <Button
          variant="secondary"
          className="st-cta-sidebar__button"
          style={{ border: "none" }}
        >
          Schedule a free consultation
        </Button>
      </div>
      <div className="st-cta-sidebar__card">
        <h3 className="st-cta-sidebar__title">Create a job alert</h3>
        <p className="st-cta-sidebar__description">
          Enter your email to get all new jobs in your inbox
        </p>
        <Button
          variant="secondary"
          className="st-cta-sidebar__button"
          style={{ border: "none" }}
        >
          Job Alert
        </Button>
      </div>
    </aside>
  );
}
