import { Icon } from "@iconify/react";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";

const Post = ({ image, title, description, author, date, articleId }) => {
  return (
    <div className="col-lg-4">
      <div className="st-post st-style3">
        <Link
          to={`/article/${articleId}`}
          className="st-post-thumb st-link-hover-wrap st-zoom"
        >
          {image ? (
            <img
              className="st-zoom-in st-article-image"
              src={image}
              alt={title}
            />
          ) : (
            <div style={{ height: "200px" }}>
              <Skeleton height="100%" />
            </div>
          )}
          <span className="st-link-hover">
            <i>
              <Icon icon="fa6-solid:link" />
            </i>
          </span>
        </Link>
        <div className="st-post-info">
          <h2 className="st-post-title">
            <Link to={`/article/${articleId}`}>
              {title || <Skeleton width={200} />}
            </Link>
          </h2>
          <div className="st-post-meta">
            <span className="st-post-date">
              {date || <Skeleton width={100} />}
            </span>
            <span>
              Posted by:
              <Link to="#" className="st-post-avatar">
                <span className="st-post-avatar-text">
                  {author || <Skeleton width={100} />}
                </span>
              </Link>
            </span>
          </div>
          <div className="st-post-text-cover">
            <p className="st-post-text">
              {description || <Skeleton count={3} />}
            </p>
          </div>
        </div>
        <div className="st-post-footer">
          <Link
            to={`/article/${articleId}`}
            className="st-btn st-style2 st-color1 st-size-medium"
          >
            Read More
          </Link>
        </div>
      </div>
      <div className="st-height-b0 st-height-lg-b30" />
    </div>
  );
};

export default Post;
