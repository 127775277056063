import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes, useLocation } from "react-router-dom";
import PageNotFound from "./Components/404/PageNotFound";
import Layout from "./Components/Layout/Layout";
import Article from "./Pages/Article";
import Home from "./Pages/Home";
import Jobs from "./Pages/Jobs";
import PastExams from "./Pages/PastExams";

const App = () => {
  const queryClient = new QueryClient();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const helmetContext = {};

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider context={helmetContext}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="article/:articleId" element={<Article />} />
            <Route path="past-board-exam-papers" element={<PastExams />} />
            <Route path="jobs" element={<Jobs />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </HelmetProvider>
    </QueryClientProvider>
  );
};

export default App;
