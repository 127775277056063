import ReactModal from "react-modal";
import "./Modal.css";

// Set modal app element for accessibility
ReactModal.setAppElement("#root");

const Modal = ({ isOpen, hideButton, onClose, book }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="st-book-modal"
      overlayClassName="st-book-modal-overlay"
    >
      <div className="st-book-modal-content">
        <button className="st-modal-close" onClick={onClose}>
          ×
        </button>
        <div className="row g-4">
          <div className="col-lg-4 col-md-5 col-sm-12">
            <img
              src={book?.image}
              alt={book?.title}
              className="st-modal-image img-fluid"
            />
          </div>
          <div className="col-lg-8 col-md-7 col-sm-12">
            <h2 className="st-modal-title">{book?.title}</h2>
            <div className="st-modal-price">R{book?.price}</div>
            <p className="st-modal-description">{book?.description}</p>
            {!hideButton && (
              <button
                className="st-btn st-style1 st-color1 st-size-medium"
                onClick={() => (window.location.href = book?.link)}
              >
                Learn More
              </button>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
