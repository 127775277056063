import { useState } from "react";
import { Button } from "../../ui/Button";

const calculateDaysAgo = (dateString: string): string => {
  const [day, month, year] = dateString.split("/").map(Number);
  const postDate = new Date(year, month - 1, day); // month is 0-based in JS
  const today = new Date();

  const diffTime = today.getTime() - postDate.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) return "Today";
  if (diffDays === 1) return "Yesterday";
  return `${diffDays} days ago`;
};

interface Job {
  id: number;
  title: string;
  company: string;
  location: string;
  salary: number;
  type: string;
  posted: string;
  description: string;
  apply: string;
}

interface JobListProps {
  jobs: Job[];
}

export default function JobList({ jobs }: JobListProps) {
  const [expandedJobs, setExpandedJobs] = useState<number[]>([]);

  const toggleDescription = (jobId: number) => {
    setExpandedJobs((prev) =>
      prev.includes(jobId)
        ? prev.filter((id) => id !== jobId)
        : [...prev, jobId]
    );
  };

  return (
    <div className="st-job-list">
      {jobs.map((job) => (
        <div key={job.id} className="st-job-list__card">
          <div className="st-job-list__header">
            <div>
              <h3 className="st-job-list__company">{job.company}</h3>
              <h2 className="st-job-list__title">{job.title}</h2>
              <div className="st-job-list__meta">
                <span>
                  {job.salary > 0
                    ? `R${job.salary.toLocaleString()}`
                    : "Unspecified"}
                </span>
                <span>{job.type}</span>
                <span>{job.location}</span>
              </div>
            </div>
            <span className="st-job-list__posted">
              {calculateDaysAgo(job.posted)}
            </span>
          </div>
          <p className="st-job-list__description">
            {expandedJobs.includes(job.id)
              ? job.description
              : job.description.slice(0, 120)}
            <span
              className="st-job-list__read-more"
              onClick={() => toggleDescription(job.id)}
            >
              {expandedJobs.includes(job.id) ? " show less" : "... read more"}
            </span>
          </p>
          <div className="st-job-list__footer">
            <Button
              onClick={() => window.open(job.apply, "_blank")}
              variant="secondary"
              className="st-job-list__suggest-button"
            >
              Apply
            </Button>
            {/* <div className="st-job-list__actions">
              <div className="st-job-list__like-button">
                <Heart />
              </div>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
}
